import { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import {
    getOrderDetails,
    getSpecsFile,
    getQCZipFile,
    getSpecSheetsFile
    // getQCZipFile,
    // getSpecSheetsFile,
} from "../../services/idt.service";
import Loading from "../Loading.component";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import Separator from "../Separator.component";
import { buildGenericXlsx } from "../../services/genericXlsxBuilder.service";
import { Buffer } from 'buffer';
import Alert from "../Alert/Alert.component";

const OrderDetail = (props: any) => {
    const {selectedRow} = props;
 
    useEffect(() => {
        if (selectedRow) {
            setShowModal(true);
            _getOrderDetail();
        }
    }, [selectedRow]);

    const [alert, setAlert] = useState({type: "", message: ""});

    const [loadingOrderDetail, setLoadingOrderDetail] = useState(false);
    const [orderDetailsItems, setOrderDetailsItems] = useState([]);

    const _getOrderDetail = async () => {
        setLoadingOrderDetail(true);
        await getOrderDetails(selectedRow.SalesOrderNumber).then((res: any) => {
            if (res.message === "OK") {
                setOrderDetailsItems(res.orderDetails.SalesOrders);
                setLoadingOrderDetail(false);
                return;
            }

            console.log(res);
            setLoadingOrderDetail(false);
        }).catch((err: any) => {
            console.log(err);
            setLoadingOrderDetail(false);
        });
    };

    const gridStyle = { minHeight: "80vh" };

    const detailColumns = [
        { name: 'CompanyBranchId', header: 'Company Branch Id', maxWidth: 180, defaultFlex: 2, },
        { name: 'SalesOrderNumber', header: 'Sales Order Number', maxWidth: 190, defaultFlex: 2, },
        { name: 'RefId', header: 'Ref Id', maxWidth: 110, defaultFlex: 2, },
        { name: 'MfgId', header: 'Mfg Id', maxWidth: 100, defaultFlex: 2, },
        { name: 'SeqDesc', header: 'Sequence Description', maxWidth: 250, defaultFlex: 2, },
        { name: 'ProductId', header: 'Product Id', maxWidth: 120, defaultFlex: 2, },
        { name: 'ProductName', header: 'Product Name', maxWidth: 180, defaultFlex: 2, },
        { name: 'PurifName', header: 'Purif Name', maxWidth: 150, defaultFlex: 2, },
        { name: 'PurifId', header: 'Purif Id', maxWidth: 110, defaultFlex: 2, },
        { name: 'Status', header: 'Status', maxWidth: 120, defaultFlex: 2, },
    ];

    const filterValue = [
        { name: 'CompanyBranchId', operator: 'eq', type: 'string', value: '', },
        { name: 'SalesOrderNumber', operator: 'eq', type: 'number', value: null, },
        { name: 'RefId', operator: 'eq', type: 'string', value: '', },
        { name: 'MfgId', operator: 'eq', type: 'string', value: '', },
        { name: 'SeqDesc', operator: 'eq', type: 'string', value: '', },
        { name: 'ProductId', operator: 'eq', type: 'string', value: '', },
        { name: 'ProductName', operator: 'eq', type: 'string', value: '', },
        { name: 'PurifName', operator: 'eq', type: 'string', value: '', },
        { name: 'PurifId', operator: 'eq', type: 'string', value: '', },
        { name: 'Status', operator: 'eq', type: 'string', value: '', },
    ];

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!showModal) {
            setOrderDetailsItems([]);
        }
    }, [showModal]);

    const [loadingDownloadSpecsFile, setLoadingDownloadSpecsFile] = useState(false);
    const [, setSpecsFile] = useState([] as any);
    const downloadSpecsFile = async () => {
        setLoadingDownloadSpecsFile(true);
        await getSpecsFile(selectedRow.SalesOrderNumber).then(async (res: any) => {
            if (res.message === "OK") {
                setSpecsFile(res.specs.Data);
                await buildGenericXlsx(res.specs.Data).then((buffer: Buffer) => {
                    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `COA-${selectedRow.SalesOrderNumber}.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
                setAlert({type: "success", message: "COA (Specs) file will be downloaded shortly"});
                setLoadingDownloadSpecsFile(false);
                return;
            }

            console.log("Error", res);
            setAlert({type: "danger", message: "Error generating COA (Specs)"});
            setLoadingDownloadSpecsFile(false);
        }).catch((err: any) => {
            console.log(err);
            setAlert({type: "danger", message: "Error generating COA (Specs)"});
            setLoadingDownloadSpecsFile(false);
        });
    };
    
    const [loadingDownloadQCFiles, setLoadingDownloadQCFiles] = useState(false);
    const downloadQCFiles = async () => {
        setLoadingDownloadQCFiles(true);
        await getQCZipFile(selectedRow.SalesOrderNumber).then((res: any) => {
            if (res.status && res.status !== 200) {
                console.log("Error downloading QC files", res);
                setAlert({type: "danger", message: "Error generating QC Files"});
                setLoadingDownloadQCFiles(false);
                return;
            }

            const blob = new Blob([res], {type: 'application/zip'});
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `qc-${selectedRow.SalesOrderNumber}.zip`;
            a.click();
            setAlert({type: "success", message: "File will be downloaded shortly"});
            setLoadingDownloadQCFiles(false);
            return;
        }).catch((err: any) => {
            console.log("ERROR",err);
            setAlert({type: "danger", message: "Error generating QC Files"});
            setLoadingDownloadQCFiles(false);
        });
    };

    const [loadingDownloadSpecSheets, setLoadingDownloadSpecSheets] = useState(false);
    const downloadSpecSheets = async () => {
        setLoadingDownloadSpecSheets(true);
        await getSpecSheetsFile(selectedRow.SalesOrderNumber).then((res: any) => {
            if (res.status) {
                console.log("Error downloading spec sheets", res);
                setAlert({type: "danger", message: "Error generating Spec Sheets"});
                setLoadingDownloadSpecSheets(false);
                return;
            }

            const blob = new Blob([res], {type: 'application/zip'});
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `spec-sheet-${selectedRow.SalesOrderNumber}.zip`;
            a.click();
            setAlert({type: "success", message: "File will be downloaded shortly"});
            setLoadingDownloadSpecSheets(false);
        }).catch((err: any) => {
            console.log("Error", err);
            setAlert({type: "danger", message: "Error generating Spec Sheets"});
            setLoadingDownloadSpecSheets(false);
        });
    };

    return <>
        <Alert alert={alert} />
        <Modal show={showModal} size="xl" dialogClassName="w-98">
            <Modal.Header closeButton onClick={() => setShowModal(false)}>
                <Modal.Title>Order #{selectedRow ? selectedRow.SalesOrderNumber : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Loading loading={loadingOrderDetail} />

                {!loadingOrderDetail && orderDetailsItems.length > 0 && <>

                    <div className="row">
                        <div className="col-12 col-md-10">
                            <ReactDataGrid
                                idProperty="RefId"
                                columns={detailColumns}
                                dataSource={orderDetailsItems}
                                style={gridStyle}
                                pagination={false}
                                defaultFilterValue={filterValue}
                                enableSelection
                                multiSelect
                            />

                            <Separator size={10} />

                            <div className="text-muted">
                                <small>{orderDetailsItems.length} items found</small>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="d-grid">
                                <button
                                    onClick={() => downloadSpecsFile()}
                                    disabled={loadingDownloadSpecsFile}
                                    className="btn btn-outline-primary">
                                        <Loading loading={loadingDownloadSpecsFile} parent="inline" />
                                        {!loadingDownloadSpecsFile && <i className="fas fa-file-pdf me-2"></i>} 
                                        Generate COA (Specs)
                                </button>

                                <Separator size={20} />
                                
                                <button
                                    onClick={() => downloadQCFiles()}
                                    disabled={loadingDownloadQCFiles || selectedRow.StatusId !== "Shipped"}
                                    className="btn btn-outline-primary">
                                        <Loading loading={loadingDownloadQCFiles} parent="inline" />
                                        {!loadingDownloadQCFiles && <i className="fas fa-file-archive me-2"></i>} 
                                        Generate QC Files
                                </button>

                                <Separator size={20} />


                                <button
                                    onClick={() => downloadSpecSheets()}
                                    disabled={loadingDownloadSpecSheets || selectedRow.StatusId !== "Shipped"}
                                    className="btn btn-outline-primary">
                                        <Loading loading={loadingDownloadSpecSheets} parent="inline" />
                                        {!loadingDownloadSpecSheets && <i className="fas fa-file-archive me-2"></i>} 
                                        Generate Spec Sheets
                                </button>

                            </div>
                        </div>
                    </div>

                </>}
            </Modal.Body>
        </Modal>
    </>
};

export default OrderDetail;