import axios from 'axios';
import Orders from './views/Orders/Orders.view';

import './App.scss';

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

axios.interceptors.request.use(function (config) {
  const url = new URL(window.location.href);
  const accessToken: any = url.searchParams.get('access_token');
  let token: string = "";
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
  const user: any = localStorage.getItem('accessToken');
  if (!user) return config;
  token = (user) ? accessToken : '';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

function App() {
  return (
    <div className="container">
      <Orders />
    </div>
  );
}

export default App;
