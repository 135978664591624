import { useEffect, useState } from "react";
import { getOrdersFromIDT } from "../../services/idt.service";
import Loading from "../../components/Loading.component";
import Separator from "../../components/Separator.component";
import dayjs from "dayjs";
import OrderDetail from "../../components/OrderDetail/OrderDetail.component";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import Alert from "../../components/Alert/Alert.component";

const Orders = (props: any) => {
    useEffect(() => {
        _getOrders();
    }, []);

    const [alert, setAlert] = useState({type: "", message: ""});

    const [orders, setOrders] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const gridStyle = { minHeight: "80vh" };

    const userColumns = [
        { name: 'EndUserNumber', header: 'End User Number', maxWidth: 170, defaultFlex: 2, },
        { name: 'InvoiceNbr', header: 'Invoice Number', maxWidth: 160, defaultFlex: 2, },
        { name: 'ItemOnOrder', header: 'Items On Order', maxWidth: 150, defaultFlex: 2, },
        {
            name: 'OrderDate', header: 'Order Date', maxWidth: 200, defaultFlex: 2,
            render: ({ value }: any) => {
                return <div>{dayjs(value).format("MM/DD/YYYY HH:mm:ss")}</div>;
            }
        },
        { name: 'OrgNbr', header: 'Org Number', maxWidth: 150, defaultFlex: 2, },
        { name: 'SalesOrderNumber', header: 'Order Number', maxWidth: 150, defaultFlex: 2, },
        { name: 'StatusId', header: 'Status', maxWidth: 150, defaultFlex: 2, },
    ];

    const filterValue = [
        { name: 'EndUserNumber', operator: 'eq', type: 'string', value: '', },
        { name: 'InvoiceNbr', operator: 'eq', type: 'string', value: '', },
        { name: 'ItemOnOrder', operator: 'eq', type: 'number', value: null, },
        { name: 'OrderDate', operator: 'eq', type: 'string', value: '', },
        { name: 'OrgNbr', operator: 'eq', type: 'string', value: '', },
        { name: 'SalesOrderNumber', operator: 'eq', type: 'number', value: null, },
        { name: 'StatusId', operator: 'startsWith', type: 'string', value: '', },
    ];

    const _getOrders = async () => {
        setLoadingOrders(true);
        await getOrdersFromIDT().then((res: any) => {
            if (res.message === "OK") {
                if (res.orders && res.orders.SalesOrders) {
                    const sortedOrders = res.orders.SalesOrders.sort((a: any, b: any) => {
                        return dayjs(b.OrderDate).isAfter(dayjs(a.OrderDate)) ? 1 : -1;
                    });
                    setOrders(sortedOrders);
                }
                setLoadingOrders(false);
                return;
            }
            console.log("RES _getOrders", res);
            setAlert({type: "danger", message: "Error getting orders"});
            setLoadingOrders(false);
        }).catch((err: any) => {
            console.log(err);
            setAlert({type: "danger", message: "Error getting orders"});
            setLoadingOrders(false);
        });
    };

    const [selectedRow, setSelectedRow] = useState(null);

    return <>
        <Alert alert={alert} />

        <div className="container mt-4">
            <h2>IDT Orders</h2>
            <p className="text-muted">
                Listing of all orders from idtdna.com
            </p>
        </div>

        <Separator size={10} />

        <Loading loading={loadingOrders} />

        {!loadingOrders && orders.length > 0 && <>
            <ReactDataGrid
                idProperty="SalesOrderNumber"
                columns={userColumns}
                dataSource={orders}
                style={gridStyle}
                pagination={false}
                defaultFilterValue={filterValue}
                enableSelection
                onSelectionChange={(selection: any) => setSelectedRow(selection.data)}
            />

            <Separator size={10} />

            <div className="text-muted">
                <small>{orders.length} orders found</small>
            </div>

            <OrderDetail selectedRow={selectedRow} />
        </>}
    </>
};

export default Orders;