import axios from 'axios';

export const getOrdersFromIDT = async () => {
  return await axios.get("/orders").then((res) => res.data).catch((err) => err);
};

export const getOrderDetails = async (orderNumber: number) => {
  return await axios.get(`/orders/${orderNumber}`).then((res) => res.data).catch((err) => err);
};

export const getSpecsFile = async (orderNumber: number) => {
  return await axios.get(`/orders/${orderNumber}/specs`).then((res) => res.data).catch((err) => err);
};

export const getQCZipFile = async (orderNumber: number) => {
  return await axios.get(`/orders/${orderNumber}/qczipfile`, {
    responseType: 'arraybuffer',
    headers: {'Content-Type': 'application/zip'}
  }).then((res: any) => {
    console.log("Error getQCZipFile", res);
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {return});
};

export const getSpecSheetsFile = async (orderNumber: number) => {
  return await axios.get(`/orders/${orderNumber}/specSheets`, {
    responseType: 'arraybuffer',
    headers: {'Content-Type': 'application/zip'}
  }).then((res: any) => {
    console.log("Error getSpecSheetsFile", res);
    if (res.status === 200) {
      return res.data;
    }
    return res;
  }).catch((err) => {return});
};
